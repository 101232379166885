import { addDays, addHours, addMinutes, addSeconds, printTimeBetween, treatAsUTC } from "../../tsunami/utils/date";
import { app } from "..";
import { DropImage, DropImages } from "./DropImage";
import { get, isUndefined } from 'lodash';

const factory = document.createElement("div");

export function getTextRecursively(element, text) {
    for (let i = 0; i < element.children.length; i++) {
        let textContent = element.children[i].textContent;
        text = text + textContent;
        getTextRecursively(element.children[i], text);
    }
}

export function parseHTMLText(value) {
    factory.innerHTML = value;
    return factory.textContent;
}

export class Drops {

    static fromJSON(array, DropClass = Drop, {includeInactive = false} = {}) {
        const drops = [];
        if (array) {
            // const yesterday = addDays(app.model.nowDate, -1);
            // const nextMonth = addDays(app.model.nowDate, 30);
            array.forEach((data, i) => {
                // Filter out publishedContent subType other than thread
                if (data.publishedContent.subType === "thread") {
                    // Filter out products other than footwear
                    const merchProduct = data.productInfo[0].merchProduct;
                    const hideProductIds = [];
                    const hideFromUpcoming = get(data, 'publishedContent.properties.custom.hideFromUpcoming');
                    console.log('hideFromUpcoming')
                    if (hideFromUpcoming) {
                        hideFromUpcoming.forEach((prod) => {
                            if (!isUndefined(prod.productId)) {
                                hideProductIds.push(prod.productId);
                            }
                        });
                    }
                    const productAvailabilityId = get(data, 'productInfo[0].availability.id');
                    const doHideFromUpcoming = (hideProductIds.indexOf(productAvailabilityId) !== -1);
                    const isActive = (merchProduct.status.toUpperCase() === "ACTIVE");

                    if ((isActive || includeInactive) && doHideFromUpcoming === false) {
                        const drop = new DropClass();
                        drop.fromJSON(data, drops.length);

                        // filter out drops not within next month and previous to now
                        // const startDateGreaterThanYesterday = (drop.startEntryDate > yesterday);
                        // const startDateWithinThisMonth = (drop.startEntryDate < nextMonth);
                        if (drop.images.length >= 3) {
                          drops.push(drop);
                        }

                    }
                }
            });
        }
        // Uncomment this line to test the grid with 12 shoes
        // return drops.slice(0, 12);
        return drops;
    }

    static loadImages(drops) {
        let promises = [];
        drops.forEach((drop, i) => {
            // const imagesLength = (i < 12) ? drop.images.length : 1;
            // const imagesLength = drop.images.length;
            const imagesLength = (drop.isDroppingToday) ? drop.images.length : 1;
            for (let j = 0; j < imagesLength; j++) {
                let image = drop.images[j];
                const imagePromise = image.preload();
                promises.push(imagePromise);
            }
        });
        return Promise.all(promises);
    }

}

export default class Drop {

    constructor() {
        this.images = [new DropImage()];
        this.index = NaN;
        this.styleColor = "";
        this.id = "";
        this.key = "";
        this.startEntryDate = null;
        this.title = "";
        this.subtitle = "";
        this.description = "";
        this.fulltitle = "";
        this.slug = "";
        this.urlSlug = "";
        this.brand = null;
        this.colorDescription = null;
        this.productType = "";
        this.inStock = false;
        this.isFootwear = false;
    }

    get isDroppingToday() {
        const now = this.now();
        return (now.getDate() == this.startEntryDate.getDate() && now.getMonth() == this.startEntryDate.getMonth());
    }

    get isDroppingWithin24Hours() {
        const previousDayDate = addDays(this.now(), -1);
        const nextDayDate = addDays(this.now(), 1);
        return (this.startEntryDate > previousDayDate && this.startEntryDate < nextDayDate);
    }

    get isDroppingThisWeek() {
        const nextWeekDate = addDays(this.now(), 7);
        return (this.startEntryDate < nextWeekDate);
    }

    get hasImageGallery() {
        return (this.images.length > 1);
    }

    now() {
        return app?.model?.nowDate ?? new Date();
    }

    getDropTime() {
        return printTimeBetween(this.startEntryDate, this.now());
    }

    fromJSON(obj, index) {
        this.id = obj.id;
        this.key = obj.id;
        this.index = index;
        this.scc = obj?.productInfo?.[0]?.merchProduct?.styleColor;
        // console.log(index, obj.productInfo[0].merchProduct.publishType);

        this.productType = obj.productInfo[0].merchProduct.productType;
        this.brand = obj.productInfo[0].merchProduct?.brand;
        this.colorDescription = obj.productInfo[0]?.productContent?.colorDescription;
        this.isFootwear = (obj.productInfo[0].merchProduct.productType.toUpperCase() == "FOOTWEAR");

        if (obj.productInfo[0].launchView) {
            this.startEntryDate = new Date(obj.productInfo[0].launchView.startEntryDate);
        } else {
            this.startEntryDate = new Date(obj.productInfo[0].merchProduct?.commercePublishDate ?? obj.productInfo[0].merchProduct?.commerceStartDate);
        }

        // hardcode the first shoe as dropping today if running in dev mode.
        if (app.debug && index == 0 && !app.model.inStockMode) {
            this.startEntryDate = addSeconds(addMinutes(addHours(this.now(), 0), 30), 45);
        }
        // this.slug = obj.productInfo[0].productContent.slug;
        // use id as slug because of the duplicates in the api
        this.slug = this.id;
        this.urlSlug = obj.productInfo[0]?.productContent?.slug;
        this.title = obj.publishedContent.properties.title;
        const body = obj.publishedContent.nodes[0].properties.body;
        if (body) this.description = parseHTMLText(body);
        let nodes = obj.publishedContent.nodes;
        const carouselNodes = [];
        const textNodes = [];
        nodes.forEach((node, j) => {
            if (node.subType == "carousel") carouselNodes.push(node);
            if (node.subType == "text") textNodes.push(node);
        });
        let textNode;
        if (textNodes.length > 0) {
            textNode = textNodes[0];
        }
        if (carouselNodes.length > 0) {
            nodes = carouselNodes[carouselNodes.length - 1].nodes;
            textNode = carouselNodes[carouselNodes.length - 1];
        } else {
            // nodes = [nodes[0]];
            nodes = [obj.publishedContent.properties.coverCard];
        }
        if (textNode) {
            this.description = parseHTMLText(textNode.properties.body);
            if (textNode.properties.subtitle) this.subtitle = textNode.properties.subtitle;
            if (textNode.properties.title) this.title = textNode.properties.title;
        }

        const publishTypes = [];
        obj.productInfo.forEach((pInfo) => {
            publishTypes.push(pInfo.merchProduct.publishType);
        });
        // console.log(index, (publishTypes.indexOf("LAUNCH") != -1), publishTypes);

        let fulltitle = "";
        if (this.subtitle) fulltitle += this.subtitle;
        if (fulltitle.length > 0) fulltitle += " ";
        fulltitle += this.title;
        this.fulltitle = fulltitle;

        // Filter out images for shoes not dropping today
        // if (!this.isDroppingToday) nodes = [nodes[0]];
        this.images = DropImages.fromJSON(nodes, index);

        // if (index == 0) this.images[0].url = "https://static.nike.com/a/images/t_PDP_1728_v1/w_720,h_720,c_fill/bc58454c-f306-47d0-bd4e-623a936173fc/what-you-got.png";
        // if (index == 0) this.images[0].url = "/assets/images/bleed-test.png";

        return this;
    }
}
