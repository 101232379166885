import { loadImage } from "../../tsunami/load/loadImage";
import * as PIXI from 'pixi.js';
import Point from "../../tsunami/geom/Point";

export class DropImages {

    static fromJSON(array, index) {
        const images = [];
        array?.forEach((data, i) => {
            const image = new DropImage();
            image.fromJSON(data, i, index);

            if (image && !image.ignore) {
                images.push(image);
            }
        })
        return images;
    }

}

export class DropImage {

    constructor(url) {
        this.url = url;
        this.image = new Image();
        this.texture = PIXI.Texture.from(this.image);
        this.altText = "";
        this.view = null;
        this.id = null;
        this.type = null;
        this.isBleeding = false;
        this.ignore = false;
    }

    fromJSON(obj, index, dropIndex) {
        this.index = index;
        this.dropIndex = dropIndex;
        if (!obj || !obj?.properties) {
            return null;
        }

        let imgData = obj.properties.squarish;
        if (!imgData?.id) imgData.id = obj.properties.squarishId;
        if (!imgData.url) {
            imgData = obj.properties.portrait;
            if (!imgData?.id) imgData.id = obj.properties.portraitId;
        }

        if (!imgData.url){
            this.ignore = true;
            return;
        }

        let imageSplit = imgData.url?.split("/");

        let imageName = imageSplit.pop();
        imageName = imageName.split(".").shift() + ".png";
        let imageId = imageSplit.pop();
        // this.url = "https://static.nike.com/a/images/t_PDP_1728_v1/w_960,c_limit,b_transparent/" + imageId + "/" + imageName;
        this.url = "https://static.nike.com/a/images/t_PDP_1728_v1/w_720,h_720,c_fill,b_transparent/" + imageId + "/" + imageName;
        this.altText = obj.properties.altText;
        this.view = imgData.view;
        this.id = imageId;
        this.type = imgData.type;
        return this;
    }

    preload() {
        let promise = Promise.resolve();

        if (this.url) {
            promise = loadImage(this.url, this.image, "anonymous").then(() => {
                this.isBleeding = testForBleed(this.image);
                if (this.isBleeding) {
                    const canvas = scaleDownBleedingImage(this.image, 0.6);
                    this.texture = PIXI.Texture.from(canvas);
                } else {
                    this.texture = PIXI.Texture.from(this.image);
                };
            });
        }
        return promise;
    }

}

const bleedCanvas = document.createElement("canvas");
const bleedContext = bleedCanvas.getContext("2d");

export function testForBleed(image) {
    bleedCanvas.width = image.width;
    bleedCanvas.height = image.height;
    bleedContext.drawImage(image, 0, 0);
    const imageData = bleedContext.getImageData(0, 0, bleedCanvas.width, bleedCanvas.height);
    const topLeft = getPixel(imageData.data, 0);
    const topRight = getPixel(imageData.data, image.width - 1);
    const bottomLeft = getPixel(imageData.data, image.width * image.height - image.width);
    const bottomRight = getPixel(imageData.data, image.width * image.height - 1);
    return (topLeft.a > 0 || topRight.a > 0 || bottomLeft.a > 0 || bottomRight.a > 0);
}

export function getPixel(data, pixelIndex) {
    const index = pixelIndex * 4;
    const r = data[index];
    const g = data[index + 1];
    const b = data[index + 2];
    const a = data[index + 3];
    return { r, g, b, a };
}

export function scaleDownBleedingImage(image, scale) {
    const canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;
    const context = canvas.getContext("2d");
    const newSize = new Point(image.width, image.height).multiplyScalar(scale).math(Math.round);
    const newPos = new Point(image.width, image.height).subtract(newSize).multiplyScalar(0.5).math(Math.round);
    context.drawImage(image, newPos.x, newPos.y, newSize.x, newSize.y);
    return canvas;
}